<template>
  <el-container>
    <el-main
        style="overflow:auto; height:100%; padding: 0;">
      <el-backtop target=".infinite-list"></el-backtop>
      <div v-infinite-scroll="loadInfinite"
           class="infinite-list"
           infinite-scroll-distance="50"
           style="overflow:auto; height:calc(100vh - 52px); padding-top: 10px">

        <div class="container" style="padding-left: 5px; padding-right: 5px">
          <el-row type="flex" justify="center">
            <!--左边栏-->
            <!--          <el-col :xs="2" :sm="2" :md="2" :lg="4" :xl="4">-->
            <!--            <div class="grid-content bg-purple">-->
            <!--              &lt;!&ndash;              <el-card class="box-card">&ndash;&gt;-->
            <!--              &lt;!&ndash;                <div slot="header" class="clearfix">&ndash;&gt;-->
            <!--              &lt;!&ndash;                  <span style="font-weight: 600">分类</span>&ndash;&gt;-->
            <!--              &lt;!&ndash;                  &lt;!&ndash;                  <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>&ndash;&gt;&ndash;&gt;-->
            <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--              &lt;!&ndash;                暂时还没搞&ndash;&gt;-->
            <!--              &lt;!&ndash;              </el-card>&ndash;&gt;-->
            <!--            </div>-->
            <!--          </el-col>-->
            <!--中间栏-->
            <el-col :xs="24" :sm="22" :md="18" :lg="16" :xl="16">
              <div class="grid-content bg-purple">
                <el-card class="box-card" @click.native="checkIsLogin($event)">
                  <!--视频链接-->
                  <template v-if="postForm.editBlogId !== 0 && $store.state.userinfo.isAdmin">
                    <el-tag size="small" closable style="margin-bottom: 10px" type="warning" @close="delEditBlogId">
                      编辑文章id：{{
                        postForm.editBlogId
                      }}
                    </el-tag>
                  </template>
                  <!--文本框-->
                  <div class="textarea-wrapper">
                    <input type="text" placeholder="标题（选填）" v-model="postForm.title">
                    <textarea placeholder="有什么想分享给大家？" v-model="postForm.content" ref="textarea"
                              :style="{'height': postDivHeight}"></textarea>
                  </div>
                  <!--图片上传-->
                  <el-progress :percentage="percent" style="width: 30%; margin-top: 10px"
                               v-if="showPercent"></el-progress>
                  <div class="upload-wrapper" v-show="postForm.fileList.length !== 0">
                    <el-upload
                        ref="filElem"
                        :on-preview="preview"
                        :on-remove="handleRemove"
                        :on-change="changeFile"
                        :before-upload="beforeUpload"
                        :file-list="postForm.fileList"
                        list-type="picture-card"
                        action="#"
                        :http-request="r2upload"
                        class="upload-button"
                    >
                      <i class="el-icon-plus"/>
                    </el-upload>
                    <el-dialog title="图片" :visible.sync="showDialog">
                      <img :src="imgUrl" style="width: 100%" alt=""/>
                    </el-dialog>
                  </div>
                  <!--视频链接-->
                  <template v-if="postBV">
                    <el-tag size="small" closable style="margin-top: 10px" @close="delPostVideo">{{
                        this.postBV
                      }}
                    </el-tag>
                  </template>
                  <!--底部按钮-->
                  <div class="posting-bottom-bar">
                    <div class="left">
                      <div class="left-item" @click="choiceImg"><i class="iconfont icon-tupian"
                                                                   style="font-size: 28px; margin-right: 5px"></i>图片
                      </div>
                      <div class="left-item" @click="showVideoDialog"><i class="iconfont icon-shipin"
                                                                         style="font-size: 25px; margin-right: 5px"></i>视频
                      </div>
                    </div>
                    <div class="right">
                      <el-button type="primary" round @click="post" :loading="postBtnLoading">发布</el-button>
                    </div>
                  </div>
                </el-card>
              </div>

              <!--内容列表-->
              <div class="grid-content bg-purple content-list">
                <el-card class="box-card" :body-style="{ padding: 0 }">
                  <template v-for="(item, index) in discussListData">
                    <div :key="index" class="discuss-data-item">
                      <div class="profile">
                        <div class="profile-left" style="margin-right: 5px">
                          <el-avatar size="medium" :src="item.user_figureurl_qq_1"></el-avatar>
                        </div>
                        <div class="profile-middle">
                          <h4>{{ item.user_nickname }}
                            <el-tag v-if="item.isLineup" type="success" size="small"
                                    style="margin-left: 10px">已添加至地图
                            </el-tag>
                          </h4>
                          <div class="profile-time">{{ item.create_time }}</div>
                        </div>
                        <el-popconfirm
                            v-if="$store.state.userinfo.isAdmin"
                            title="确定删除吗？"
                            @confirm="delBlog(item.id, index)"
                        >
                          <el-button slot="reference" type="text">删除</el-button>
                        </el-popconfirm>
                      </div>
                      <div class="detail" @click="openDiscussDetail(item)">
                        <div class="detail-title">
                          {{ item.title }}
                        </div>
                        <div class="detail-content" style="padding-bottom: 10px">
                          {{ item.content }}
                        </div>
                      </div>


                      <!--                    <div class="images" v-if="item.imageList !== 0 && !item.videoSrc">-->
                      <!--                      <template v-for="(imgItem, imgIndex) in item.imageList">-->
                      <!--                        <el-image-->
                      <!--                            style="margin-bottom: 10px;margin-right: 10px;width: 180px; height: 180px"-->
                      <!--                            :src="imgItem"-->
                      <!--                            :preview-src-list="item.imageList"-->
                      <!--                            :key="imgIndex"-->
                      <!--                        >-->
                      <!--                        </el-image>-->
                      <!--                      </template>-->
                      <!--                    </div>-->
                      <!--iframe多了有点卡-->
                      <div v-if="item.videoSrc" style="font-size: 16px">
                        视频地址：
                        <a :href="item.linkWithSecond" target="_blank">{{ item.linkWithSecond }}</a>
                        <!--                      <div class="biliVideo">-->
                        <!--                        <iframe :src="item.videoSrc" allowfullscreen="allowfullscreen"></iframe>-->
                        <!--                      </div>-->
                      </div>
                      <div class="toolbar">
                        <div></div>
                        <div class="toolbar-operation">
                          <div class="toolbar-operation-comment" @click="openDiscussDetail(item)">
                            <i class="el-icon-chat-line-square" style="font-size: 16px"></i>
                            {{ item.comment_num }}
                          </div>

                        </div>
                      </div>
                    </div>
                    <el-divider :key="index"></el-divider>
                  </template>
                </el-card>
              </div>
            </el-col>
            <!--          右边栏-->
            <!--          <el-col :xs="2" :sm="2" :md="2" :lg="4" :xl="4">-->
            <!--            <div class="grid-content bg-purple">-->
            <!--              &lt;!&ndash;              <el-card class="box-card">&ndash;&gt;-->
            <!--              &lt;!&ndash;                <div slot="header" class="clearfix">&ndash;&gt;-->
            <!--              &lt;!&ndash;                  <span style="font-weight: 600">暂时不知道放什么</span>&ndash;&gt;-->
            <!--              &lt;!&ndash;                  &lt;!&ndash;                  <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>&ndash;&gt;&ndash;&gt;-->
            <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--              &lt;!&ndash;              </el-card>&ndash;&gt;-->
            <!--            </div>-->
            <!--          </el-col>-->
          </el-row>
        </div>

      </div>
    </el-main>
    <!--添加视频信息-->
    <el-dialog title="添加视频" :visible.sync="videoDialogVisible" :close-on-click-modal="false" width="30%">
      <el-form :model="videoForm">
        <el-form-item label="B站视频链接：" label-width="110px">
          <el-input v-model="videoForm.link"></el-input>
        </el-form-item>
        <el-form-item label="B站嵌入代码：" label-width="110px" v-if="$store.state.userinfo.isAdmin">
          <el-input v-model="videoForm.biliIframe"></el-input>
        </el-form-item>
      </el-form>
      <el-switch
          v-model="videoForm.videoStartTimeSwitch"
          active-text="精准空降"
          style="margin: 0 10px"
      >
      </el-switch>
      <template v-if="videoForm.videoStartTimeSwitch">
        <el-input-number v-model="videoForm.videoStartMinute" :controls="false" :min="0"
                         size="mini"></el-input-number>
        分
        <el-input-number v-model="videoForm.videoStartSecond" :controls="false" :min="0" :max="59"
                         size="mini"></el-input-number>
        秒
      </template>
      <div slot="footer" class="dialog-footer">
        <el-button @click="videoDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitVideoForm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="登录LKVAL"
        :visible.sync="loginDialogVisible"
        width="30%">
      <div class="header-btn">
        <el-button type="primary" size="small" @click="openQQLogin">
          QQ登录
        </el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import calcTextareaHeight from '@/common/calcTextareaHeight';
import COS from 'cos-js-sdk-v5'
import axios from 'axios'
import {biliLinkToIframe} from "@/common/bili";
import {imgNameToUrl} from "@/common/comm";

var config = {
  Bucket: 'lkval-1259337012',
  Region: 'ap-nanjing'
}
var getAuthorization = function (options, callback) {
  var url = '/api/blogs/sts';
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.onload = function (e) {
    try {
      var data = JSON.parse(e.target.responseText);
      var credentials = data.credentials;
    } catch (e) {
      console.log(e)
    }
    callback({
      TmpSecretId: credentials.tmpSecretId,
      TmpSecretKey: credentials.tmpSecretKey,
      SecurityToken: credentials.sessionToken,
      ExpiredTime: data.expiredTime, // SDK 在 ExpiredTime 时间前，不会再次调用 getAuthorization
    });
  };
  xhr.send();
}

var nanoid = (t = 21) => {
  let e = "", r = crypto.getRandomValues(new Uint8Array(t));
  for (; t--;) {
    let n = 63 & r[t];
    e += n < 36 ? n.toString(36) : n < 62 ? (n - 26).toString(36).toUpperCase() : n < 63 ? "_" : "-"
  }
  return e
};
export default {
  name: "Discuss",
  data() {
    return {
      source: null, // 存放取消的请求方法
      discussListData: [],
      postForm: {
        editBlogId: 0,
        title: '',
        content: '',
        biliLink: '',
        biliIframe: '',
        startMinute: 0,
        startSecond: 0,
        fileList: [],
      },
      // fileList: [], // 图片地址设置为数组
      postDivHeight: '48px', //文本框高度
      postBV: '', // 小标签提示添加视频
      postBiliIframe: '', //暂时没用，处理iframe
      showDialog: false, // 控制显示弹层
      imgUrl: "", //图片放大预览
      currentFileUid: null, //确定上传图片的id
      showPercent: false, //状态加载条
      percent: 0,//进度条加载过程
      videoDialogVisible: false,
      videoForm: {
        link: '',
        biliIframe: '',
        videoStartTimeSwitch: false,
        videoStartMinute: 0,
        videoStartSecond: 0,
      },
      loginDialogVisible: false,
      infiniteCount: 1,
      postBtnLoading: false,
    }
  },
  watch: {
    'postForm.content'() { //实时改变文本框高度
      this.getHeight();
    },
    '$store.state.userinfo.nickname'() {
      this.loadInfinite()
    },
  },
  mounted() {
    // loadInfinite()会自动加载一次
    // this.getDiscussList()
    this.handleEditBlog()
  },
  methods: {
    loadInfinite() {
      this.getDiscussList()
    },
    //预览图片时的回调
    preview(file) {
      this.imgUrl = file.url; //点击预览时，传递图片地址给弹出层
      this.showDialog = true;
    },
    //删除图片的回调
    handleRemove(file) {
      // file是点击删除的文件
      //   将原来的文件给排除掉了 剩下的就是最新的数组了
      this.postForm.fileList = this.postForm.fileList.filter((item) => item.uid !== file.uid);
    },
    changeFile(file, fileList) {
      this.postForm.fileList = fileList.map((item) => item);
    },
    beforeUpload(file) {
      // 要开始做文件上传的检查了
      // 文件类型 文件大小
      const types = ["image/jpeg", "image/gif", "image/bmp", "image/png"];

      /*  if (!types.includes(file.type)) {
        this.$message.error('上传图片只能是 JPG、GIF、BMP、PNG 格式!')
        return false
      } */
      if (!types.some((item) => item === file.type)) {
        //此时说明上传的类型不是规定类型
        this.$message.warning("上传图片只能是 JPG、GIF、BMP、PNG 格式!");
        return false; //停止上传
      }
      //  检查大小
      //1mb 等于 1024KB 1KB=1024B
      //限制大小为5mb
      const maxSize = 5 * 1024 * 1024;
      if (maxSize < file.size) {
        this.$message.error("图片大小最大不能超过5M");
        return false;
      }
      //此时upload组件会为文件对象生成一个id属性
      this.currentFileUid = file.uid;
      //显示加载进度条
      this.showPercent = true;
      return true;
    },
    upload(params) {
      if (params.file) {
        axios({
          method: 'get',
          url: '/api/blogs/sts',
        }).then(r => {
          let data = r.data
          let credentials = data.credentials;
          let cos = new COS({
            // 必选参数
            getAuthorization: function (options, callback) {
              // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
              // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
              // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
              callback({
                TmpSecretId: credentials.tmpSecretId,
                TmpSecretKey: credentials.tmpSecretKey,
                SecurityToken: credentials.sessionToken,
                ExpiredTime: data.expiredTime, // SDK 在 ExpiredTime 时间前，不会再次调用 getAuthorization
              });
            }
          });
          var filename = data.imgFileName + '.' + params.file.name.replace(/.+\./, "")
          var webp_filepath = filename.split('.')[0] + '.' + 'webp'

          // 执行上传操作
          cos.uploadFile(
              {
                Bucket: config.Bucket, // 存储桶
                Region: config.Region, // 地域
                Key: 'lkval/main/' + filename, // 文件名
                Body: params.file, // 要上传的文件对象
                SliceSize: 1024 * 1024 * 5, // 大于5mb才进行分块上传
                // 上传到腾讯云 =》 哪个存储桶 哪个地域的存储桶 文件  格式  名称 回调
                Headers: {
                  'Pic-Operations': `{"is_pic_info": 1, "rules": [{"fileid": "${webp_filepath}", "rule": "imageMogr2/format/webp/interlace/0/quality/75"}]}`
                },
                onProgress: (params) => {
                  this.percent = params.percent * 100;
                },
              },
              (err, data) => {
                // 这个回调需要使用箭头函数，因为currentFileUid在当前组件实例对象上，
                //箭头函数没有this,会继承upload方法的this，它的this执行vue组件实例

                // data中有一个statusCode === 200 的时候说明上传成功
                if (!err && data.statusCode === 200) {
                  //   此时说明文件上传成功  云服务器会返回一个地址，我们需要把该地址放到组件的img标签上
                  //  此时我们要将fileList中的数据的url地址变成 现在上传成功的地址
                  // 目前虽然是一张图片 但是请注意 我们的fileList是一个数组
                  // 需要知道当前上传成功的是哪一张图片

                  // this.fileList = this.fileList.map((item) => {
                  //   // 去找谁的uid等于刚刚记录下来的id
                  //   if (item.uid === this.currentFileUid) {
                  //     //Location为传回的地址，但是没有http字段
                  //     // 将成功的地址赋值给原来的url属性
                  //     return {url: "http://" + data.Location, upload: true};
                  //     // upload 为true 表示这张图片已经上传完毕 这个属性要为我们后期应用的时候做标记
                  //     // 保存  => 图片有大有小 => 上传速度有快又慢 =>要根据有没有upload这个标记来决定是否去保存
                  //   }
                  //   return item;
                  // });
                  for (let j = 0, len = this.postForm.fileList.length; j < len; j++) {
                    if (this.postForm.fileList[j].uid === params.file.uid) {
                      this.postForm.fileList[j].url = "http://" + data.Location
                      this.postForm.fileList[j].upload = true
                      this.postForm.fileList[j].status = 'success'
                    }
                  }

                  // 将上传成功的地址 回写到了fileList中 fileList变化  =》 upload组件 就会根据fileList的变化而去渲染视图
                  setTimeout(() => {
                    this.showPercent = false; // 隐藏进度条
                    this.percent = 0; // 进度归0
                  }, 1000);
                }
              }
          );
        })


      }
    },

    r2upload(params) {
      if (params.file) {
        axios({
          method: 'get',
          url: '/api/blogs/get_upload_url',
        }).then(r => {
          let data = r.data
          let upload_url = data.url;

          // 执行上传操作
          return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            //element-ui默认使用post提交，但是我们生成的url需要使用 put 提交
            //需要指定 put 直传，以及this.uploadUrl（后端返回的url）
            xhr.open('PUT', upload_url, true);
            //设置上传文件的类型
            xhr.setRequestHeader('Content-Type', params.file.type); // 或者 file.type
            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  for (let j = 0, len = this.postForm.fileList.length; j < len; j++) {
                    if (this.postForm.fileList[j].uid === params.file.uid) {
                      this.postForm.fileList[j].url = imgNameToUrl(data.img_name)
                      this.postForm.fileList[j].upload = true
                      this.postForm.fileList[j].status = 'success'
                    }
                  }

                  // 将上传成功的地址 回写到了fileList中 fileList变化  =》 upload组件 就会根据fileList的变化而去渲染视图
                  setTimeout(() => {
                    this.showPercent = false; // 隐藏进度条
                    this.percent = 0; // 进度归0
                  }, 1000);

                  resolve(xhr.response);
                  //进入到这里就表明上传成功了
                  //我这里对后端返回的url进行截取方便存入数据库，因为前端还需要展示图片
                } else {
                  reject(xhr.statusText);
                }
              }
            };
            xhr.onerror = () => {
              reject(xhr.statusText);
            };
            //发送
            xhr.send(params.file); //或者 file
          });
        })
      }
    },
    getHeight() { //动态改变文本框高度
      this.postDivHeight = calcTextareaHeight(this.$refs.textarea, 1, null).height;
    },
    choiceImg() { //点击图片按钮也能上传图片
      // this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
      document.querySelector('.el-upload__input').click();
    },
    submitVideoForm() {
      try {
        const videoUrl = this.videoForm.link
        const bv = videoUrl.match(/(BV.*?).{10}/)[0]
        this.postBV = bv
        let p = null;
        try {
          p = videoUrl.match(videoUrl.match(/p=(\d+)/)[1]);
        } catch (error) {
          p = null
        }
        let result = "player.bilibili.com/player.html?bvid=" + bv
        // 这里有点问题，应该是 &page=
        if (p) {
          result += "&p=" + p
        }
        this.postBiliIframe = '<iframe src="//' + result
        this.postBiliIframe += '" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>'
        this.postForm.biliLink = 'https://www.bilibili.com/video/' + bv
        if (p) {
          this.postForm.biliLink += "?p=" + p
        }
        this.postForm.biliIframe = this.videoForm.biliIframe
        this.postForm.startMinute = this.videoForm.videoStartMinute
        this.postForm.startSecond = this.videoForm.videoStartSecond
        this.videoDialogVisible = false
      } catch (e) {
        this.$message.error("视频链接转换失败");
        console.log("嵌入链接转换失败:" + e)
      }
    },
    delPostVideo() {
      this.postBV = ''
      this.postForm.biliLink = ''
      this.postForm.startMinute = 0
      this.postForm.startSecond = 0
    },
    post() {
      if (!this.$store.state.userinfo.figureurl_qq_1) {
        return
      }
      if (this.postForm.title.trim() === '' && this.postForm.content.trim() === '') {
        this.$message.error("标题和内容需要有一项不为空")
        return
      }
      this.postForm['game'] = this.$store.state.currentPage
      this.postBtnLoading = true
      axios({
        method: 'post',
        url: '/api/blogs/post',
        data: this.postForm
      }).then(r => {
        if (r.data.code === 0) {
          this.postForm.content = ''
          this.postForm.biliLink = ''
          this.postForm.biliIframe = ''
          this.postForm.startMinute = 0
          this.postForm.startSecond = 0
          this.postForm.fileList = []
          this.postBV = ''
          if (!this.$store.state.userinfo.isAdmin) {
            this.postForm.title = ''
            this.videoForm = {
              link: '',
              biliIframe: '',
              videoStartTimeSwitch: false,
              videoStartMinute: 0,
              videoStartSecond: 0,
            }
          }
          this.$message({
            message: '发布成功',
            type: 'success'
          });
          this.infiniteCount = 1
          this.discussListData = []
          this.getDiscussList()
        } else {
          this.$message.error(r.data.msg);
        }
        this.postBtnLoading = false
      }).catch(error => {
        console.log(error)
        this.$message.error("发生错误")
        this.postBtnLoading = false
      })
    },
    cancelRequest() {
      if (typeof this.source === 'function') {
        this.source('终止请求')
      }
    },
    getDiscussList() {
      const _this = this;
      this.cancelRequest();
      axios({
        method: 'get',
        url: '/api/blogs/get_discuss_list',
        params: {
          infiniteCount: this.infiniteCount,
          game: this.$store.state.currentPage
        },
        cancelToken: new axios.CancelToken(function executor(c) {
          _this.source = c
        })
      }).then(r => {
        if (r.data.code === 0) {
          let tmpList
          tmpList = r.data.list
          let infiniteCount = r.data.infinite_count
          for (let j = 0; j < tmpList.length; j++) {
            // 处理图片
            tmpList[j].imageList = []
            for (let i = 0; i < tmpList[j].images.length; i++) {
              //tmpList[j].imageList.push(`https://lkval-1259337012.cos.ap-nanjing.myqcloud.com/lkval/main/${tmpList[j].images[i].split('.')[0] + '.' + 'webp'}`)
              tmpList[j].imageList.push(imgNameToUrl(tmpList[j].images[i]))
            }
            // 处理视频
            if (tmpList[j].bili_link) {
              const [videoSrc, linkWithSecond] = biliLinkToIframe(tmpList[j].bili_link, tmpList[j].bili_start_minute, tmpList[j].bili_start_second, tmpList[j].bili_iframe)
              tmpList[j].videoSrc = videoSrc
              tmpList[j].linkWithSecond = linkWithSecond
            }
          }
          if (infiniteCount === 1) {
            this.discussListData = tmpList
          } else {
            this.discussListData = this.discussListData.concat(tmpList)
          }
          this.infiniteCount = infiniteCount + 1
        }
      })
    },
    openDiscussDetail(item) {
      window.open('/discuss/' + item.id)
    },
    checkIsLogin(e) {
      if (!this.$store.state.userinfo.figureurl_qq_1) {
        e.preventDefault()
        this.loginDialogVisible = true
      }
    },
    showVideoDialog() {
      if (this.$store.state.userinfo.figureurl_qq_1) {
        this.videoDialogVisible = true
      }
    },
    delBlog(itemId, index) {
      axios({
        method: 'post',
        url: '/api/blogs/del_discuss',
        data: {
          obj_id: itemId,
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.discussListData.splice(index, 1)
          this.$message({
            message: '删除成功',
            type: 'success'
          });
        }
      })
    },
    handleEditBlog() {
      if (this.$route.query.blogid) {
        this.postForm.editBlogId = this.$route.query.blogid
        axios({
          method: 'get',
          url: '/api/blogs/get_discuss_detail',
          params: {
            blog_id: this.$route.query.blogid,
          }
        }).then(r => {
          let obj = r.data.detail
          this.postForm.title = obj.title
          this.postForm.content = obj.content
          this.postForm.biliLink = obj.bili_link
          this.postForm.biliIframe = obj.bili_iframe
          this.postForm.startMinute = obj.bili_start_minute
          this.postForm.startSecond = obj.bili_start_second
          for (let i = 0; i < obj.images.length; i++) {
            this.postForm.fileList.push({
              // url: 'http://lkval-1259337012.cos.ap-nanjing.myqcloud.com/lkval/main/' + obj.images[i],
              url: imgNameToUrl(obj.images[i]),
              status: 'success'
            })
          }
          this.videoForm.link = obj.bili_link
          this.videoForm.biliIframe = obj.bili_iframe
          this.videoForm.videoStartTimeSwitch = true
          this.videoForm.videoStartMinute = obj.bili_start_minute
          this.videoForm.videoStartSecond = obj.bili_start_second
          this.postBV = this.videoForm.link.match(/(BV.*?).{10}/)[0]
        })
      }
    },
    delEditBlogId() {
      this.postForm.editBlogId = 0
    },
    openQQLogin() {
      localStorage.setItem('urlBeforeLogin', window.location.href)
      window.location = 'https://graph.qq.com/oauth2.0/authorize?response_type=token&client_id=102047093&redirect_uri=https://lkval.com/qqlogin'
    },
  }
}
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  /*background: #d3dce6;*/
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.textarea-wrapper {
  background: #f0f1f4;
  border-radius: 10px;
  padding: 10px;
}

.textarea-wrapper:focus-within {
  background: #ffffff;
  border: 1px solid #409eff;
}

.textarea-wrapper input {
  outline: none;
  resize: none;
  width: 100%;
  background: transparent;
  border: none;
  font-weight: 600;
  font-size: 20px;
}

.textarea-wrapper textarea {
  outline: none;
  resize: none;
  width: 100%;
  background: transparent;
  border: none;
  font-size: 18px;
  min-height: 54px;
}

.upload-wrapper {
  margin-top: 10px;
}

.posting-bottom-bar {
  display: flex;
  margin-top: 5px;
}

.posting-bottom-bar .left {
  display: flex;
  flex-grow: 1;
}

.posting-bottom-bar .left .left-item {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-right: 15px;
}

.posting-bottom-bar .left .left-item:hover {
  color: #409eff;
  cursor: pointer;
}

.posting-bottom-bar .right {
  display: flex;
  align-items: center;
}

.el-input-number--mini {
  width: 50px;
}

.content-list {
  margin-top: 20px;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: inherit;
}

.detail-title {
  font-size: 16px;
  font-weight: 700;
  color: #232426;
}

.profile-time {
  font-size: 12px;
  color: #8e969c;
}

.discuss-data-item {
  padding: 20px;
}

.el-divider--horizontal {
  margin: 0;
}

.biliVideo {
  margin-top: 5px;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60%; /* 高度应该是宽度的56% */
}

.biliVideo iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.header-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-btn-login {
  width: 80px;
  height: 32px;
  border: 1px #0066ff solid;
  border-radius: 3px;
  color: white;
  background: #0066ff;
  cursor: pointer;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toolbar-operation {
  display: flex;
  align-items: center;
  justify-content: inherit;
  margin-right: 20px;
}

.toolbar-operation-comment {
  cursor: pointer;
}

.profile-middle {
  flex: 1;
}
</style>